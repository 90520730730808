import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo } from 'components';
import Header from 'components/Header';
import HeaderNew from 'components/HeaderNew';

import EmailForm from './components/EmailForm';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { createLead, updateQuizAnswers, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import {
  useFirstVisitDate,
  usePageView,
  useQuizData,
  useTrackingQueryParams,
} from 'utils/hooks';
import { Events } from 'utils/events';
import { Quiz } from 'types/quiz';
import theme from 'utils/theme';
import BlackFridayBanner from 'components/BlackFridayBanner';
import Tracking from 'utils/tracking';
import Progress from '../quiz/components/Progress';
import { title } from 'process';
import { cliendCodeToUrl } from 'utils/clientCodeUrl';
import { getCurrencyFromLocale, normalizeStates } from 'utils/localization';

const HeaderContainer = styled.div`
  position: relative;
  max-width: 500px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.lightBg};
  @media ${mobile} {
    margin: unset;
    padding-top: unset;
  }
`;

const PageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light0};
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  padding: 3.5rem 1rem;
  gap: 1.5rem;
  height: calc(100vh - 68px);
  background: #f6f6f6;
  @media ${tablet} {
    padding: 1rem 1rem;
    gap: 0.75rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  max-width: 28.75rem;
`;

const Title = styled(Text)`
  text-align: center;
  font-family: 'Afacad', sans-serif;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  width: 100%;
  margin-bottom: 1.5rem;

  @media ${tablet} {
    font-size: 1.625rem;
  }
`;

const Subtitle = styled(Text)`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
`;

const Footer = styled(Text)`
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 auto;
  max-width: 28.75rem;
`;

const ImageContainer = styled.div`
  margin: 4rem 0rem;
  width: 16rem;
  height: 16rem;

  @media ${mobile} {
    max-width: 7.5rem;
    width: 100%;
    height: 7.5rem;
  }
`;

const EmailFormStyled = styled(EmailForm)`
  margin-top: 0.5rem;
`;

const Email: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { quiz_answers, user, geolocation, user_ip } = useSelector(
    (state: AppState) => state.user,
  );
  const trackingParams = useTrackingQueryParams();
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();

  const { isMobile } = useQuery();

  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;

  const dispatch = useDispatch();

  const { goToCheckout, goToResults } = useRouter();

  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  useEffect(() => {
    window?.scroll(0, 0);
  }, [window.location.pathname]);

  const handleNextRoute = () => {
    if (variant === 'email-first') {
      return goToResults();
    }
    goToCheckout({ discount: false });
  };

  const handleGetResults = async (email: string) => {
    setError('');

    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(
          createLead({
            email,
            funnel: quiz,
            quiz_answers: { ...quiz_answers, ...trackingParams },
          }),
        )) ?? '';
      dispatch(updateQuizAnswers(trackingParams));

      cliendCodeToUrl(code);

      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('steps')) {
        urlParams.delete('steps');
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }

      const userState = normalizeStates(
        geolocation?.iso_country || '',
        geolocation?.iso_state || '',
      );

      Tracking.leadCreated(
        code,
        email,
        geolocation?.iso_country,
        quiz_answers?.gender ? quiz_answers?.gender[0] : null,
        userState,
        user_ip,
        getCurrencyFromLocale(quiz_answers?.locale || 'US'),
      );

      handleNextRoute();
    } catch (error) {
      setError(error?.response.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('email');

  if (!data) {
    return <LoadingPage />;
  }
  const renderTitle = (text: string) => {
    const regex = /\${name}/;
    const name = quiz_answers?.name;
    const replacedString = text.replace(regex, name);
    return replacedString;
  };

  return (
    <>
      <Seo />
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}
      <PageContainer>
        <Header
          logoVariant="center"
          sticky={false}
          color={'white'}
          hasDivider={false}
          hasShadow={true}
        />
        <Container>
          <ContentContainer>
            <Title
              textAlign="center"
              dangerouslySetInnerHTML={{ __html: renderTitle(data?.title) }}
            />
            <Subtitle>{data?.inputLabel}</Subtitle>
            <EmailFormStyled
              loading={loading}
              error={error}
              onSubmit={handleGetResults}
              continueBtnTitle={data?.continueBtnTitle}
              placeholder={data?.inputPlaceholder}
            />
            {data.img ? (
              <ImageContainer>
                <DynamicImage
                  src={data.img}
                  alt={data.alt}
                  width="100%"
                  height="100%"
                />
              </ImageContainer>
            ) : null}

            <Footer>{data?.footer}</Footer>
          </ContentContainer>
        </Container>
      </PageContainer>
    </>
  );
};

export default Email;
